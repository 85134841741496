<template>
  <div class="my-auto w-full max-w-2xl mx-auto">
    <container>
      <transition
        appear
        appear-active-class="appear-from"
        appear-to-class="appear-to"
      >
        <div>
          <h1
            class="text-2xl sm:text-3xl font-headings text-center mb-12"
            v-text="$t('title.404')"
          />
        </div>
      </transition>
    </container>
  </div>
</template>

<script>
import Container from "../components/Ui/Container.vue";

export default {
  name: "NotFound",
  components: { Container },
  metaInfo() {
    return { title: this.$t("title.404") };
  },
};
</script>
